import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Block, Content, Flex, FlexSpacer, Input, Yoga } from 'gerami'

import { PodcastsPageQuery } from '../../../../graphql-types'
import { useSearch } from '../../../shared/contexts/search-index-context/search-index-context'
import useSearchMatch from '../../../shared/hooks/use-search-match/use-search-match'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import PodcastCard from '../../components/podcast-card/podcast-card'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'

type PodcastsProps = {}

const Podcasts: React.FC<PodcastsProps> = ({}) => {
  const data = useStaticQuery<PodcastsPageQuery>(query)

  const [term, setTerm] = useState(``)
  const results = (useSearch(term, { expand: true }) || []).filter(
    result => result.template === 'podcast'
  )

  const featuredPodcasts = useSearchMatch(
    data.featuredPodcasts.edges.map(edge => edge.node),
    term.length ? results : undefined
  )
  const otherPodcasts = useSearchMatch(
    data.otherPodcasts.edges.map(edge => edge.node),
    term.length ? results : undefined
  )

  return (
    <>
      <SEO title="Podcasts" />

      <Layout>
        <Content transparent size="4XL">
          <Block first last>
            <Flex>
              <h1 className="h0">Podcasts</h1>

              <FlexSpacer />

              <div
                className="margin-left-very-big padding-top-big full-width"
                style={{ maxWidth: 420 }}
              >
                <Input
                  type="search"
                  value={term}
                  onChange={e => setTerm(e.target.value)}
                  placeholder="Search"
                  className="middle full-width"
                />
              </div>
            </Flex>

            {featuredPodcasts.length <= 0 ? null : (
              <>
                <h4 className="subtitle">
                  Featured Podcast
                  {featuredPodcasts.length === 1 ? `` : `s`}
                  {term ? (
                    <>
                      {' '}
                      Matching <q>{term}</q>
                    </>
                  ) : null}
                </h4>

                <hr />

                <Yoga maxCol={2} className="margin-bottom-big">
                  {featuredPodcasts.map((node, i) => (
                    <PodcastCard
                      key={i}
                      slug={node?.fields?.slug!}
                      thumbnail={node?.frontmatter?.thumbnail}
                      title={node?.frontmatter?.title!}
                      date={node?.frontmatter?.date!}
                      excerpt={node?.excerpt || ``}
                    />
                  ))}
                </Yoga>
              </>
            )}

            <h4 className="subtitle">
              {featuredPodcasts.length > 0 ? `Other` : `All`} Podcast
              {otherPodcasts.length === 1 ? `` : `s`}
              {term ? (
                <>
                  {' '}
                  Matching <q>{term}</q>
                </>
              ) : null}
            </h4>

            <hr />

            {otherPodcasts.length <= 0 ? (
              <div className="margin-vertical-big">
                No {featuredPodcasts.length > 0 ? `more ` : ``}
                podcasts found.
              </div>
            ) : (
              <Yoga maxCol={3}>
                {otherPodcasts.map((node, i) => (
                  <PodcastCard
                    key={i}
                    slug={node?.fields?.slug!}
                    thumbnail={node?.frontmatter?.thumbnail}
                    title={node?.frontmatter?.title!}
                    date={node?.frontmatter?.date!}
                    excerpt={node?.excerpt || ``}
                  />
                ))}
              </Yoga>
            )}
          </Block>
        </Content>

        <EmailRegistration />
      </Layout>
    </>
  )
}

export default Podcasts

export const query = graphql`
  query PodcastsPage {
    featuredPodcasts: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "podcast" }, isFeatured: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail
            title
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
    otherPodcasts: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "podcast" }, isFeatured: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            thumbnail
            title
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
